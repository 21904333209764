body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  font-size: 16px;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: Monaco, Consolas, 'Courier New',
    monospace;
}

.link {
  cursor: pointer;
}

.header {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 5px black;
}

.header-content {
  width: 100%;
  max-width: 1000px;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.header-title {
  font-weight: bold;
  margin: 0 20px 0 10px;
  width: 120px;
  user-select: none;
}

.header-nav {
  margin: 10px 0;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

.header-nav-item {
  margin-right: 10px;
}

.container {
  flex-grow: 1;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 200px;
  overflow-y: auto;
}

.container>div {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teams {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image-grid-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.image-grid-header {
  font-weight: 700;
  margin-bottom: 5px;
}
.image-grid-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-grid-image > img {
  height: 45px;
  width: 45px;
  background-image: url('./assets/images/checker-background.png');
  background-repeat: repeat;
}

.image-grid-cell {
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: 1px solid black;
  padding: 5px 5px;
  cursor: pointer;
  min-width: 110px;
  font-size: 14px;
  color: black;
  text-decoration: none;
}

.team:hover {
  color: black;
  text-decoration: underline;
}

.team>img {
  width: 45px;
}

.image-uploader {
  margin-bottom: 20px;
}

.image-uploader-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}

.image-uploader-container > img, .image-uploader-box > img {
  width: 200px;
  height: 200px;
  background-image: url('./assets/images/checker-background.png');
  background-repeat: repeat;
}

.image-uploader-box {
  width: 200px;
  height: 200px;
}

.image-metadata {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 180px;
  margin: 10px;
}

.players-page-options {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.players-page-options > .dropdown {
  margin: 0 10px;
}
